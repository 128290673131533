<template>
  <div class="setting-item s-contact-info-type">
    <esmp-radio-group
      v-model="localSetting.value"
      :items="localSetting.options.map((el) => ({ value: el.name, label: el.title }))"
    />
  </div>
</template>

<script>
export default {
  name: 'SContactInfoType',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
